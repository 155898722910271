import React from 'react'

const ProductItem = ({ iconClass, title, description, delay }) => (
  <div className={`col-lg-3 col-md-6 col-sm-12 wow bounceInUp`} data-wow-delay={delay}>
    <div className="bg-light rounded service-item">
      <div className="service-content d-flex align-items-center justify-content-center p-4">
        <div className="service-content-icon text-center">
          <i className={`fas ${iconClass} fa-7x text-primary mb-4`} />
          <h4 className="mb-3">{title}</h4>
          <p className="mb-4">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

function Products() {
  const products = [
    { iconClass: 'fa-lemon', title: 'Coconut Products', description: 'Explore our diverse range of coconut-based products.', delay: '0.1s' },
    { iconClass: 'fa-mortar-pestle', title: 'Spice Blends', description: 'Authentic spice blends for an exquisite South Indian culinary experience.', delay: '0.2s' },
    { iconClass: 'fa-pepper-hot', title: 'Indian Spices', description: 'Renowned Indian spices that enhance the gastronomy of your food style.', delay: '0.3s' },
    { iconClass: 'fa-wheat-awn', title: 'Dry Fruits', description: 'Premium quality dry fruits sourced from the finest orchards.', delay: '0.4s' },
    { iconClass: 'fa-apple-alt', title: 'Fruits', description: 'A wide selection of fresh and juicy fruits for a healthy lifestyle.', delay: '0.5s' },
    { iconClass: 'fa-carrot', title: 'Vegetables', description: 'Fresh and organic vegetables directly from local farms.', delay: '0.6s' },
    { iconClass: 'fa-tshirt', title: 'Garments', description: 'Traditional and stylish garments with fine quality fabrics.', delay: '0.7s' },
    { iconClass: 'fa-puzzle-piece', title: 'Indian Handicrafts', description: 'Exquisite handicrafts showcasing India\'s rich cultural and traditional heritage.', delay: '0.8s' },
  ];

  return (
    <>
      <div className="container-fluid service py-6" id="services">
        <div className="container">
          <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
            <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Products</small>
            <h1 className="display-5 mb-5">What We Offer</h1>
          </div>
          <div className="row g-4">
            {products.map((product, index) => (
              <ProductItem
                key={index}
                iconClass={product.iconClass}
                title={product.title}
                description={product.description}
                delay={product.delay}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products