import React from 'react'

function Footer() {
  return (
    <>
   <div className="container-fluid footer py-6 my-6 mb-0 bg-light wow bounceInUp" data-wow-delay="0.1s" id="contact">
  <div className="container">
    <div className="row gy-4">
      <div className="col-lg-3 col-md-6">
        <div className="footer-item">
          <img src="img/logo_resized.png" alt="SpiceMart Logo" style={{width: 150}} />
          <p className="lh-lg mb-4">Discover a world of flavors with our finest selection of spices and herbs,
            sourced from around the globe to bring magic to your kitchen.</p>
            <div className="footer-icon d-flex">
            <a className="btn btn-primary btn-sm-square me-2 rounded-circle text-white" href="https://www.facebook.com/share/158VeyQ5EP/?mibextid=LQQJ4d"><i className="fab fa-facebook-f" /></a>
            {/* <a class="btn btn-primary btn-sm-square me-2 rounded-circle text-white" href=""><i
                              class="fab fa-twitter"></i></a> */}
            <a href="https://www.instagram.com/green_wave_llp/?hl=en" className="btn btn-primary btn-sm-square me-2 rounded-circle text-white"><i className="fab fa-instagram" /></a>
            {/* <a href="#" class="btn btn-primary btn-sm-square rounded-circle text-white"><i
                              class="fab fa-linkedin-in"></i></a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="footer-item">
          <h4 className="mb-4">Division Office</h4>
          <div className="d-flex flex-column align-items-start">
            <p><i className="fa fa-map-marker-alt text-primary me-2" /><br /> Av. del Cid,<br />L'Olivereta,
              Valencia<br />Valencia, España,46018</p>
            <p><i className="fa fa-phone-alt text-primary me-2" /><br /> (+34) 627933049</p>
            {/* <p><i class="fas fa-envelope text-primary me-2"></i><br> greenwaveexportllp@gmail.com</p> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="footer-item">
          <h4 className="mb-4">Corporate Office</h4>
          <div className="d-flex flex-column align-items-start">
            <p><i className="fa fa-map-marker-alt text-primary me-2" /><br />
              Thaikkattukara,Aluva,<br />Kerala, India, 683106</p>
            <p><i className="fa fa-phone-alt text-primary me-2" /><br /> (+91) 8592877231</p>
            <p><i className="fas fa-envelope text-primary me-2" /><br /> greenwaveexportllp@gmail.com</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="footer-item">
          <h4 className="mb-4">We'll contact you.</h4>
          <form id="contactForm">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" required />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" required />
            </div>
            {/* Added message text area */}
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows={2} required defaultValue={""} />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
      {/* <div class="col-lg-3 col-md-6">
              <div class="footer-item">
                  <h4 class="mb-4">Social Gallery</h4>
                  <div class="row g-2">
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                      <div class="col-4">
                          <img src="img/menu-01.jpg" class="img-fluid rounded-circle border border-primary p-2"
                              alt="">
                      </div>
                  </div>
              </div>
          </div> */}
    </div>
  </div>
</div>

    </>
  )
}

export default Footer