import React, { createContext, useState } from 'react';

const LanguageContext = createContext();

const languages = {
  en: {
    home: "Home",
    about: "About",
    services: "Products",
    available: "Availablity",
    contact: "Contact",
    language: "Language",
    heroText: "quality products worldwide",
    aboutText: "Welcome to Greenwave Exports, your premier source for high-quality spices, coconuts,fruits, vegetables and other natural products from the lush landscapes of Kerala, India. With a deep-rooted commitment to excellence and sustainability, we bring the rich flavors and purest natural goods from the heart of Kerala to markets across the globe. Explore our diverse range of premium products, sourced directly from local farmers and cultivated with care. At Greenwave Exports, we pride ourselves on delivering freshness, quality, and authenticity to our valued customers worldwide. Join us in experiencing the essence of nature’s finest offerings."
  },
  es: {
    home: "Inicio",
    about: "Acerca de",
    services: "Servicios",
    available: "Disponibilidad",
    contact: "Contacto",
    language: "Idioma",
    heroText: "Calidad Productos en todo el mundo",
    aboutText: "Bienvenido a Greenwave Exports, su principal fuente de especias, cocos, frutas, verduras y otros productos naturales de alta calidad provenientes de los exuberantes paisajes de Kerala, India. Con un profundo compromiso con la excelencia y la sostenibilidad, llevamos los ricos sabores y los productos naturales más puros del corazón de Kerala a los mercados de todo el mundo. Explore nuestra diversa gama de productos premium, obtenidos directamente de agricultores locales y cultivados con cariño. En Greenwave Exports, nos enorgullecemos de brindar frescura, calidad y autenticidad a nuestros valiosos clientes en todo el mundo. Únase a nosotros para experimentar la esencia de las mejores ofertas de la naturaleza."
  },
  ar: {
    home: "الصفحة الرئيسية",
    about: "معلومات عنا",
    services: "خدمات",
    available: "التوفر",
    contact: "اتصل",
    language: "لغة",
    heroText: "جودة المنتجات في جميع أنحاء العالم",
    aboutText: "مرحباً بكم في شركة  Greenwave exports، مصدركم الأول للتوابل عالية الجودة، وجوز الهند، والفواكه، والخضروات، وغيرها من المنتجات الطبيعية من المناظر الخلابة في ولاية كيرالا، الهند. مع التزام عميق بالتميز والاستدامة، نجلب لكم النكهات الغنية وأفضل المنتجات الطبيعية من قلب كيرالا إلى الأسواق في جميع أنحاء العالم. اكتشفوا مجموعتنا المتنوعة من المنتجات الممتازة، التي يتم الحصول عليها مباشرة من المزارعين المحليين وتزرع بعناية. في Greenwave exports ، نفتخر بتقديم الطزاجة والجودة والأصالة لعملائنا الكرام حول العالم. انضموا إلينا في تجربة جوهر أفضل عروض الطبيعة"
  }
};

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, translations: languages[language] }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };