import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContex';

function Home() {
    const { translations } = useContext(LanguageContext);

    return (
        <>
            <div className="container-fluid bg-light py-6 my-6 mt-0" id="hero" style={{ height: '100vh' }}> {/* Adjusted height */}
                <div className="container" style={{marginTop:50}} >
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-7 col-md-12">
                        <img className="animated bounceInDown img-fluid" src="img/logo_resized.png" alt="GreenWave Logo" style={{ objectFit: 'cover', objectPosition: 'center', width: '70%', height: 'auto' }} />
                        <h1 className="display-7 mb-4 animated bounceInUp lang-text" data-key="heroText" style={{ paddingLeft: 5, paddingTop: 10 }}>
                                 {translations.heroText}
                            </h1>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <img src="img/gg-wave.png" className="img-fluid rounded animated zoomIn" alt />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home